import './Home.css'

export default function Home() {
  return (
    <>
      <div style={{marginLeft:'20px', marginRight: '20px', marginBottom: '50px'}}>
        <h1 className="title"> Thank you for being part of Ovvio </h1>
        <div style={{textAlign:'left'}}>
          <h2 className="home-subtitle">
            Then, Now, and the Future
          </h2>
          <div className='brief'>
            <span>
              Ovvio came to life during the pandemic; a time when meaningful human connections felt more important
              than ever. Together, we built a space where advice, ideas and support could flow freely.
            </span>
          </div>
          <div className='brief'>
            <span>
              But as the world has moved forward, so have the ways we interact with technology.
            </span>
          </div>
          <div className='brief'>
            <span>
              While we deeply believe in the power of human connection, we've come to realize that something is missing and our platform isn't fully 
              aligned with how people connect and engage today.
            </span>
          </div>
          <div className='brief'>
            <span>
              After much reflection, we've made the difficult decision to say goodbye to Ovvio as it exists today.
            </span>
          </div>
          <div className='brief'>
            <span>
              While this chapter is closing, it's not the end. We've taking this time to rethink and reshape
              Ovvio so it can better fit the needs of today and what can follow in the future.
            </span>
          </div>
          <div className='brief'>
            <span>
              While this chapter is closing, it's not the end. We've taking this time to rethink and reshape
              Ovvio so it can better fit the needs of today and what can follow in the future.
            </span>
          </div>
          <div className='brief' style={{fontStyle:'italic', fontWeight: '400'}}>
            <span>
              Before we go...
            </span>
          </div>
          <div className='brief'>
            <span>
              This journey wouldn't have been possible without you.
            </span>
          </div>
          <div className='brief'>
            <span>
              Thank you for being part of this community and for your trust and support. We're so grateful, and we hope
              to see you again when Ovvio's next chapter begins.
            </span>
          </div>
          <div className='brief' style={{fontWeight: '400'}}>
            <span>
              With gratitude,
              <br/>
              The Ovvio Team
            </span>
          </div>
      </div>
    </div>
    </>
  )
}
