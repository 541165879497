import { Container, Row, Col } from 'react-bootstrap';
import {useForm} from 'react-hook-form'
import './Contact.css';
import axios from 'axios'
import { useEffect, useState } from 'react';

export default function Contact() {
    const [formMessage, setFormMessage] = useState('')
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm()

    const onSubmit = formData => {
        const name = formData.name;
        const nameArray = name.split(' ')
        const firstName = nameArray[0]
        const lastName = nameArray[1]
        const newData = {
            firstName,
            lastName,
            email: formData.email,
            message: formData.message
        };
        const config = {
            Headers: { 'Content-Type': 'multipart/form-data' },
            crossDomain: true
        }

        const data = new FormData()
        data.append('firstName', newData.firstName || '')
        data.append('lastName', newData.lastName || '')
        data.append('email', newData.email)
        data.append('message', newData.message)
        data.append('access_key', '8d65aa73-5e12-43d8-b78e-019fed776410')

        console.log('Data', newData);
        
        const url = `https://api.web3forms.com/submit`;
        axios.post(url, data, config).then(data => {
            reset()
            setFormMessage(`Form submitted successfully!`)
        }).catch(() => { 
            reset(); 
            setFormMessage(`Form submit error!`)
        })
    }

    useEffect(() => {
        const timer = () => {
            setInterval(() => {
                setFormMessage('')
            }, 10000)
        }
        timer()
        return () => clearInterval(timer)
    }, [formMessage])
    return (
        <>
            <h1 className='contact-title'>Contact</h1>
            <div className='supportContactWrapper'>
                <div>
                    <Container>
                        <Row>
                            <Col lg={4}>
                                <div className="contactFormWrapper">
                                <img src="/images/face-icon.svg" alt="Background-1" className="img-fluid" />
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* <input type="hidden" name="access_key" value="8d65aa73-5e12-43d8-b78e-019fed776410" /> */}
                                        <div className='formField nameField'>
                                            <label htmlFor='name' className='commonFieldLabel'>
                                                {' '}
                                                Name
                                            </label>
                                            <input 
                                                type='text' id='name'
                                                aria-describedby='nameHelpBlock'
                                                placeholder='Your name'
                                                className='commonField'
                                                autoComplete='on'
                                                name='name'
                                                {...register('name', {required: true})}
                                            />
                                            <span className="errorMessage">{errors.name ? 'Name is required' : null}</span>
                                        </div>
                                        <div className='formField emailField'>
                                            <label htmlFor='email' className='commonFieldLabel'>
                                                {' '}
                                                Email
                                            </label>
                                            <input 
                                                type='email' id='email'
                                                aria-describedby='EmailHelpBlock'
                                                placeholder='example@mail.com'
                                                className='commonField'
                                                autoComplete='on'
                                                name='email'
                                                {...register('email', {required: true})}
                                            />
                                            <span className="errorMessage">{errors.email ? 'Email is required' : null}</span>
                                        </div>
                                        <div className='formField messageField'>
                                            <label htmlFor='message' className='commonFieldLabel'>
                                                {' '}
                                                Message
                                            </label>
                                            <input 
                                                type='text' id='message'
                                                aria-describedby='messageHelpBlock'
                                                placeholder='Your message'
                                                autoComplete='on'
                                                className="commonField"
                                                name="message"
                                                {...register('message', {required: true})}
                                            />
                                            <span className="errorMessage">{errors.message ? 'Message is required' : null}</span>
                                        </div>
                                        <div className="formButtonWrapper">
                                            <button type="submit" className="formButton">
                                                Submit message
                                            </button>
                                        </div>
                                        <span className="formMessage">{formMessage}</span>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>    
            </div>
        </>
    )
}  