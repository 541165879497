import "./Journey.css"

export default function Journey() {
  return (
    <>  
      <h1 className="title"> Ovvio's Journey </h1>
      <h2 className="subtitle"> A story of human connection in the digital age </h2>
      <div className="row" style={{paddingTop: "20px"}}>
      
        <div className="col-6 verticalLine">
          1
        </div>
        <div className="col-6 right-pane-1">
          <h3 className="right-pane-title">
            2
          </h3>
        </div>
      </div>
    </>
  )
}
