import { 
  // Link, 
  useLocation,
  // useMatch, useResolvedPath, 
} from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import React, {useState, useEffect} from 'react'
import "./Navigation.css"


export default function Navigation() {
  const [width, setWidth] = useState(0)
  const location = useLocation()
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setWidth])

  return (
    <>
    <div className="navbarWrapper desktopNabarWrapper">
      <Navbar expand={'md'} className="mb-1  mt-1">
        <Container fluid={width < 992 ? true : false}>
          <Navbar.Brand href="/" className="navbar-brand">
            <img className="logo" src="/images/ovvio_half_color.png" alt="Logo" />
          </Navbar.Brand>

          <Nav className="navlink_wrap justify-content-end flex-grow-1 pe-3" style={{marginLeft: '15px'}}>
            <NavLink className={`nav-link navbarLink ${location.pathname === '/home' ? 'active' : ''}`} to="/">
              <span>HOME</span>
            </NavLink>
            {/* <NavLink className={`nav-link navbarLink ${location.pathname === '/journey' ? 'active' : ''}`} to="/journey/">
              <span>JOURNEY</span>
            </NavLink>
            <NavLink className={`nav-link navbarLink ${location.pathname === '/faq' ? 'active' : ''}`} to="/faq/">
              <span>FAQ</span>
            </NavLink> */}
            <NavLink className={`nav-link navbarLink ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact/">
              <span>CONTACT</span>
            </NavLink>
          </Nav>
        </Container>
      </Navbar>
    </div>
    </>
    // <nav className="nav">
    //   <Link to="/" className="site-title">
    //     <img className="logo" src="logo-latest.png" alt="Logo" />
    //   </Link>
    //   <ul>
    //     <CustomLink to="/journey">Journey</CustomLink>
    //     <CustomLink to="/contact">Contact</CustomLink>
    //     <CustomLink to="/faq">FAQ</CustomLink>
    //   </ul>
    // </nav>
  )
}

// function CustomLink({ to, children, ...props }) {
//   const resolvedPath = useResolvedPath(to)
//   const isActive = useMatch({ path: resolvedPath.pathname, end: true })

//   return (
//     <li className={isActive ? "active" : ""}>
//       <Link to={to} {...props}>
//         {children}
//       </Link>
//     </li>
//   )
// }
